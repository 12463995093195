import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { UserContext } from 'providers/UserProvider';
import { useContext } from 'react';
import { IDPS } from 'utils/constants/idp';

function Advertisement() {
  const { idp, isTeacher, language } = useContext(UserContext);

  if (idp === IDPS.FTRPRF && isTeacher && language === 'nl') {
    return (
      <div className="rounded-md bg-blue-50 p-4 mb-4">
        <div className="flex">
          <div className="shrink-0">
            <InformationCircleIcon
              aria-hidden="true"
              className="size-5 text-blue-400"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              Schrijf je in voor de inspirerende studiedag DigiDay op 30 januari
              in Gent
            </p>
            <p className="mt-3 text-sm md:ml-6 md:mt-0">
              <a
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                href="http://www.digiday.be/?utm_source=ftrprf"
                rel="noreferrer"
                target="_blank"
              >
                Details
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export default Advertisement;
